import * as React from "react"
import PropTypes from "prop-types"


export const MainTitle = (props) => {
    const { content, sub } = props;

    return (
        <h1 className={`text-12.5 text-borange leading-none font-bryant`}>
            { content }
            { sub && (
                <span className={`font-cera capi text-7.5 text-bdark-gray block mt-3`}>{ sub }</span>
            )}
        </h1>
    );
}
MainTitle.propTypes = {
    content: PropTypes.node.isRequired,
    sub: PropTypes.node,
}
